import React from 'react'
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'
import { Box, Typography, Button, Menu, MenuItem, List, ListItemButton, ListItemText, Collapse } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MenuWithDropdown from './MenuWithDropdown';
import logo from '../imgs/wwf_logo.png'

export default function MainHeader() {
    const theme = useTheme();

    const assessRiskLinks = [
      {name: 'COUNTRIES', path: '/assess-risk/countries'},
      {name: 'SPECIES', path: '/assess-risk/species'},
    ]

    const understandRiskLinks = [
      {name: 'UNDERSTAND RISK', path: '/understand-risk'},
      {name: 'ORIGIN RISK', path: '/origin-risk'},
      {name: 'SPECIES RISK', path: '/species-risk'},
      {name: 'SUPPLIER & SUPPLY-CHAIN RISK', path: '/supplier-risk'},
    ]

    const manageRiskLinks = [
      {name: 'MANAGE RISK', path: '/manage-risk'},
      {name: 'WOOD ID TESTING', path: '/wood-id-testing'},
    ]

    const StyledLink = ({ props }: any) => {

        return (

            <Box flexDirection='row' alignSelf='center'>
                <Button
                    component={Link}
                    style={{ textDecoration: 'none', color: theme.palette.primary.contrastText }}
                    to={props == 'Home' ? '/' : props.toLowerCase().replaceAll(' ', '-')}
                >
                    <Typography variant='body2'>{props.toUpperCase()}</Typography>
                </Button>
            </Box>
        )
    }

    const matches = useMediaQuery('(max-width:768px)');

    // mobile menu open controls
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    // submenus open controls
    const [openAssessSubMenu, setOpenAssessSubMenu] = React.useState(true);
    const handleAssessSubMenuClick = () => {
      setOpenAssessSubMenu(!openAssessSubMenu);
    };
    const [openUnderstandSubMenu, setOpenUnderstandSubMenu] = React.useState(true);
    const handleUnderstandSubMenuClick = () => {
      setOpenUnderstandSubMenu(!openUnderstandSubMenu);
    };
    const [openManageSubMenu, setOpenManageSubMenu] = React.useState(true);
    const handleManageSubMenuClick = () => {
      setOpenManageSubMenu(!openManageSubMenu);
    };

    return (
        <Box>
            <img className='logo' src={logo} alt='wwf' />
            {!matches && <Box display='flex'
                flexDirection='row'
                justifyContent='center'
                alignItems='center'
                sx={{
                    width: '100%', background: theme.palette.primary.main
                }}>

                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-around'
                    alignItems='center'
                    width='60%'
                >
                    <StyledLink props='Home' />
                    <StyledLink props='About' />
                    <MenuWithDropdown name='Assess Risk' links={assessRiskLinks}  />
                    <MenuWithDropdown name='Understand Risk' links={understandRiskLinks}  />
                    <MenuWithDropdown name='Manage Risk' links={manageRiskLinks}  />
                    <StyledLink props='Contact' />
                </Box>
            </Box >}
            {matches && 
              <Box display='flex'
                   flexDirection='row-reverse'
                   sx={{
                    width: '100%', background: theme.palette.primary.main
                   }}
              >
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  >
                    <MenuIcon sx={{color: '#fff', width: '1.5em', height: '1.5em'}} />
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                  
                >
                  <MenuItem onClick={handleClose} component="a" href="/">Home</MenuItem>
                  <MenuItem onClick={handleClose} component="a" href="/about">About</MenuItem>
                  <MenuItem>
                    <List disablePadding>
                      <ListItemButton onClick={handleAssessSubMenuClick} sx={{padding: 0}}>
                        <ListItemText primary="Assess Risk" />
                        {openAssessSubMenu ? <ExpandMore /> : <ExpandLess />}
                      </ListItemButton>
                      <Collapse in={!openAssessSubMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton component="a" href="/assess-risk/countries" sx={{ pl: 2 }}>
                            <ListItemText primary="Countries" />
                          </ListItemButton>
                          <ListItemButton component="a" href="/assess-risk/species" sx={{ pl: 2 }}>
                            <ListItemText primary="Species" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </MenuItem>
                  <MenuItem>
                    <List disablePadding>
                      <ListItemButton onClick={handleUnderstandSubMenuClick} sx={{padding: 0}}>
                        <ListItemText primary="Understand Risk" />
                        {openUnderstandSubMenu ? <ExpandMore /> : <ExpandLess />}
                      </ListItemButton>
                      <Collapse in={!openUnderstandSubMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItemButton component="a" href="/understand-risk" sx={{ pl: 2 }}>
                            <ListItemText primary="Understand Risk" />
                          </ListItemButton>
                          <ListItemButton component="a" href="/origin-risk" sx={{ pl: 2 }}>
                            <ListItemText primary="Origin Risk" />
                          </ListItemButton>
                          <ListItemButton component="a" href="/species-risk" sx={{ pl: 2 }}>
                            <ListItemText primary="Species Risk" />
                          </ListItemButton>
                          <ListItemButton component="a" href="/supplier-risk" sx={{ pl: 2 }}>
                            <ListItemText primary="Supplier & Supply-Chain Risk" sx={{whiteSpace: "normal"}} />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </MenuItem>
                  <MenuItem>
                    <List disablePadding>
                      <ListItemButton onClick={handleManageSubMenuClick} sx={{padding: 0}}>
                        <ListItemText primary="Manage Risk" />
                        {openManageSubMenu ? <ExpandMore /> : <ExpandLess />}
                      </ListItemButton>
                      <Collapse in={!openManageSubMenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <ListItemButton component="a" href="/manage-risk" sx={{ pl: 2 }}>
                            <ListItemText primary="Manage Risk" />
                          </ListItemButton>
                          <ListItemButton component="a" href="/wood-id-testing" sx={{ pl: 2 }}>
                            <ListItemText primary="Wood ID Testing" />
                          </ListItemButton>
                        </List>
                      </Collapse>
                    </List>
                  </MenuItem>
                  <MenuItem onClick={handleClose} component="a" href="/contact">Contact</MenuItem>
                </Menu>
              </Box>
            }
        </Box>
    )
}